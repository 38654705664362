<template>
    <section class="trailers">
        <div class="container" v-if="movies.data && movies.data.length > 0">
            <div class="section-header">
                <h2 class="section-title">Trailers</h2>
            </div>
            <div class="section-content">
                <div class="col l3 m3 s4" v-for="movie in movies.data" :key="movie.id" >
                    <MovieItem :movie="movie" :showPlay="true" :showTitle="false" :showDateTag="false"/>
                </div>
            </div>
        </div>

        <div class="container" v-else>
            <div class="section-header">
                <h2 class="section-title">Trailers</h2>
            </div>
            <div class="section-content">
                <div class="col l3 m3 s4" v-for="(n,index) in 12" :key="index" >
                    <MovieItem :showPlay="true" :showTitle="false" :showDateTag="false"/>
                </div>
            </div>
        </div>
        <Spinner v-if="isLoadingMore" />
        <div class="bottom-container" v-if="loadMoreStatus"><a @click="loadMore()" class="load-more">Cargar Más</a></div>
    </section>
</template>

<script>
import MovieItem from '@/components/MovieItem.vue'
import Spinner from '@/components/Spinner.vue'

export default{
     name: 'Trailers',
     components: {
         MovieItem,
         Spinner
     },
     data(){
         return {
             movies: [],
             isLoadingMore: false,
             loadMoreStatus: true,
             page: 1
         }
     },
     mounted(){
          (async () => {
            const category = this.$route.params.category

            this.setHeader('page', {
                titulo: 'Trailers'
            })

            this.isLoading = true
            this.movies.data = []
            this.movies.meta = []
            this.loadItems()
            this.isLoading = false
        })()
     },
     methods:{
        loadItems: async function(){
            let previousMovies = this.movies.data
            
            this.movies = await this.getItems('peliculas', 12, 'page='+this.page+'filter[estado][eq]=publicado&filter[fecha_trailer][nnull]&filter[trailer][nnull]&sort=-fecha_trailer&meta=total_count,filter_count')

            this.movies.data = previousMovies.concat(this.movies.data)

            if(this.movies.meta.filter_count > this.movies.data.length){
                this.page++
                this.loadMoreStatus = true
            }else{
                this.loadMoreStatus = false
            }
        },
        loadMore: async function(){
            this.isLoadingMore = true
            await this.loadItems()
            this.isLoadingMore = false
        }
    }
}
</script>

<style scoped>
    
    @media(max-width:768px){
        .section-content .col{
            margin-bottom: 8px !important;
        }
    }

</style>
